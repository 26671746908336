import React from 'react'
import Layouts from './Layouts'
import DownloadNilai from '../components/admin/DownloadNilai'

const Download = ({ url }) => {
    return (
        <div>
            <Layouts url={url}>
                <DownloadNilai url={url} content="Download Nilai" />
            </Layouts>
        </div>
    )
}

export default Download