import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { ProgressBar, Spinner } from 'react-bootstrap';
import * as XLSX from 'xlsx';

const DownloadNilai = ({ url, content }) => {
    const [jurusan, setJurusan] = useState([]);
    const [level, setLevel] = useState('');
    const [selectedJurusan, setSelectedJurusan] = useState('');
    const [selectedRombel, setSelectedRombel] = useState('');
    const [rombel, setRombel] = useState([]);

    const [listMapel, setListMapel] = useState([]);
    const [siswa, setSiswa] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [nilai, setNilai] = useState({});



    const fetchJurusan = async () => {
        const token = localStorage.getItem("token");
        try {
            const response = await axios.get(url + "jurusan", {
                headers: { Authorization: `Bearer ${token}` },
            });
            setJurusan(response.data.data);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchRombelbyId = async () => {
        const token = localStorage.getItem("token");
        setIsLoading(true); // Start loading
        try {
            const response = await axios.post(url + "rombel-by-jurusan", {
                jurusan_id: selectedJurusan,
                level_id: level
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setRombel(response.data.data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false); // End loading
        }
    };


    const fetchSiswa = async () => {
        const token = localStorage.getItem('token');
        try {
            setIsLoading(true);
            const response = await axios.post(url + 'siswa-by-rombel', {
                rombel_nama: selectedRombel
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setSiswa(response.data.data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };


    const fetchListMapel = async () => {
        const token = localStorage.getItem("token");
        try {
            setIsLoading(true);
            const response = await axios.post(url + "get-list-mapel", {
                rombel: selectedRombel,
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setListMapel(response.data.data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchAllNilai = async () => {
        if (siswa.length === 0 || listMapel.length === 0) {
            console.log('Siswa or ListMapel is empty');
            return;
        }

        const token = localStorage.getItem('token');
        const maxConcurrentRequests = 5; // Adjust this number based on your server's capacity
        const requests = [];
        const nilaiData = {};

        const fetchNilai = async (s, mapel) => {
            try {
                const response = await axios.post(url + 'get-nilai-by-name', {
                    rombel: selectedRombel,
                    kdmp: mapel.mp,
                    nis: s.nis
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                return { nis: s.nis, mapel: mapel.mp, nilai: response.data.data };
            } catch (error) {
                console.log(error);
                return { nis: s.nis, mapel: mapel.mp, nilai: null };
            }
        };

        const processRequests = async () => {
            while (requests.length > 0) {
                const batch = requests.splice(0, maxConcurrentRequests);
                const results = await Promise.all(batch);
                results.forEach(({ nis, mapel, nilai }) => {
                    if (!nilaiData[nis]) {
                        nilaiData[nis] = {};
                    }
                    nilaiData[nis][mapel] = nilai ? nilai : {};
                });
            }
        };

        for (const s of siswa) {
            for (const mapel of listMapel) {
                requests.push(fetchNilai(s, mapel));
            }
        }

        try {
            setIsLoading(true);
            await processRequests();
            console.log('Fetched Nilai Data:', nilaiData); // Debugging line
            setNilai(nilaiData);

        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const exportToExcel = () => {
        const workbook = XLSX.utils.book_new();
        const worksheetData = [
            ['#', 'NIS', 'Nama Siswa', ...listMapel.flatMap(mapel => [mapel.mp + ' P', mapel.mp + ' K', mapel.mp + ' NA'])]
        ];

        siswa.forEach((s, index) => {
            const row = [
                index + 1,
                s.nis,
                s.nama,
                ...listMapel.flatMap(mapel => [
                    nilai[s.nis] && nilai[s.nis][mapel.mp] ? nilai[s.nis][mapel.mp].olah_p : '-',
                    nilai[s.nis] && nilai[s.nis][mapel.mp] ? nilai[s.nis][mapel.mp].olah_k : '-',
                    nilai[s.nis] && nilai[s.nis][mapel.mp] ? nilai[s.nis][mapel.mp].olah_na : '-'
                ])
            ];
            worksheetData.push(row);
        });

        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Nilai Siswa');
        XLSX.writeFile(workbook, 'Nilai_Siswa.xlsx');
    };


    useEffect(() => {
        fetchListMapel();
        fetchSiswa();
    }, [selectedRombel]);


    useEffect(() => {
        fetchRombelbyId(selectedJurusan, level);
    }, [selectedJurusan, level]);


    useEffect(() => {
        fetchJurusan()
        // fetchStatusLock();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            await fetchAllNilai();
            setIsLoading(false)
        };
        fetchData();
    }, [siswa, listMapel]);



    return (
        <div className="row">
            <div className="col xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="page-title-wrapper">
                    <div className="page-title-box">
                        <h4 className="page-title">{content}</h4>
                    </div>
                    <div className="breadcrumb-list">
                        <ul>
                            <li className="breadcrumb-link">
                                <a href="/dashboard"><i className="fas fa-home mr-2"></i>Dashboard</a>
                            </li>
                            <li className="breadcrumb-link active">{content}</li>
                        </ul>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-md-4">
                                <select className="form-control" onChange={(e) => {
                                    setLevel(e.target.value);
                                    setSelectedJurusan('');
                                    setSelectedRombel('');
                                }}>
                                    <option value="">Pilih Level / Kelas</option>
                                    <option value="3">12</option>
                                </select>
                            </div>
                            <div className="col-md-4">
                                <select className='form-control' value={selectedJurusan} onChange={(e) => {
                                    setSelectedJurusan(e.target.value);
                                    setSelectedRombel('');
                                }}>
                                    <option value="">Pilih Jurusan</option>
                                    {jurusan.map((j) => (
                                        <option key={j.id} value={j.id}>{j.jurusan}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="col-md-4">
                                <select className='form-control' value={selectedRombel} onChange={(e) => setSelectedRombel(e.target.value)}>
                                    <option value="">Pilih Rombel</option>
                                    {rombel.map((r) => (
                                        <option key={r.nama_rombel} value={r.nama_rombel}>{r.nama_rombel}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>



                    <div className="card-body">
                        <div className="row">

                            <div className="col-12 mb-3">
                                {!isLoading && (
                                    <button onClick={exportToExcel} className="btn btn-primary sm-btn">Export to Excel</button>
                                )}
                            </div>

                            <div className="table-responsive" style={{ overflowY: 'auto', maxHeight: '400px' }}>

                                <table className="table table-styled mb-0">

                                    <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>

                                        <tr>
                                            <th rowSpan={3}>#</th>
                                            <th rowSpan={3}>NIS</th>
                                            <th rowSpan={3}>Nama Siswa</th>
                                            <th colSpan={listMapel.length * 3} className="text-center">MATA PELAJARAN</th>

                                        </tr>
                                        <tr>
                                            {listMapel.map((mapel, index) => (
                                                <th key={index} colSpan={3} className='text-center'>{mapel.mp}</th>
                                            ))}
                                        </tr>
                                        <tr>
                                            {listMapel.map((_, index) => (
                                                <React.Fragment key={index}>
                                                    <th className='text-center'>P</th>
                                                    <th className='text-center'>K</th>
                                                    <th className='text-center'>NA</th>
                                                </React.Fragment>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoading ? (
                                            <tr>
                                                <td colSpan={3 + listMapel.length * 3} className='text-center'>
                                                    <Spinner animation="border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </Spinner>
                                                </td>
                                            </tr>
                                        ) : (
                                            siswa.map((s, index) => (
                                                <tr key={s.nis}>
                                                    <td>{index + 1}</td>
                                                    <td>{s.nis}</td>
                                                    <td>{s.nama}</td>
                                                    {listMapel.map((mapel, mapelIndex) => (
                                                        <React.Fragment key={mapelIndex}>
                                                            <td className='text-center'>{nilai[s.nis] && nilai[s.nis][mapel.mp] ? nilai[s.nis][mapel.mp].olah_p : '-'}</td>
                                                            <td className='text-center'>{nilai[s.nis] && nilai[s.nis][mapel.mp] ? nilai[s.nis][mapel.mp].olah_k : '-'}</td>
                                                            <td className='text-center'>{nilai[s.nis] && nilai[s.nis][mapel.mp] ? nilai[s.nis][mapel.mp].olah_na : '-'}</td>
                                                        </React.Fragment>
                                                    ))}
                                                </tr>
                                            ))
                                        )}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DownloadNilai